import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

const TermsOfServices = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="contentBodyInner termsOfServicesWrapper">
      <Container>
        <Row>
          <Col xs={12} md={12}>
          <h3>Welcome to Ownish!</h3>

<p>The purpose of this document</p>

<p>This document sets out the Ownish Terms of Use (“Terms”) and is between you (“you” or “your”) and Ownish Ltd, (“we,” “our,” “us,” or “Ownish”), and governs the use of our fashion renting and resale services (“our Services”).</p>

<p>It is important that you read and understand these Terms. If you have any questions about any of our Terms please contact <a href="mailto:hello@ownish.co.uk">hello@ownish.co.uk</a>.</p>

<p>By agreeing to use Ownish or any of our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms.</p>

<h3>Information about you and your privacy</h3>

<p>Your privacy is important to us. You should read our Privacy Policy to understand how we collect, use and share information about you. You should also read our Cookie Policy to understand how we use cookies.</p>

<h3>What is Ownish?</h3>

<p>Ownish is a peer-to-peer marketplace platform accessible via an iOS app, an Android app and a website, collectively referred to as “the Marketplace.” The Marketplace, apps and our website together form our Services.</p>

<p>The Marketplace exists to match users wanting to rent or buy items (“Shoppers”), with users that want to lend or sell items (“Listers”) and to facilitate rental or resale transactions between them.</p>

<p>Where Terms apply to both Shoppers and Listers, they are referred to as “Users” in these Terms.</p>

<p>When a Lister accepts a rental or purchase request from a Shopper, a contract is formed and is solely between the Lister and the Shopper. Ownish is not a party to any transaction or contract. Ownish is also unable to monitor or guarantee the quality of any item available in the Marketplace. However, we are available to help mediate any issues that arise in the performance of any contract formed on the Ownish Marketplace.</p>

<h3>Who is Ownish?</h3>

<p>Our Services are operated by Ownish Limited, a company incorporated and registered in England and Wales under company number 12832230 with our registered address at 57 Jordan Street, Liverpool, Merseyside, England, L1 0BW</p>

<p>You can contact Ownish at the following:</p>
<p>Email <a href="mailto:hello@ownish.co.uk">hello@ownish.co.uk</a></p>

<h3>Accepting these Terms</h3>

<p>By setting up an account with Ownish and/or using and accessing the Service, you agree to and accept these Terms in their entirety. If you do not agree to these Terms you must not use the Service.</p>

<p>We want Ownish to be a positive experience for all users, so we ask that you honour the commitments you make to one another when agreeing to lend, rent, buy or sell an item. Thank you.</p>

<h3>Making changes to these Terms</h3>

<p>These Terms and the Privacy Policy may change at any time. If we do make changes, we will update these Terms and specify key changes at the top of this page. We may also send you an email or push notification to notify you of any changes. Changes take effect on the date set out at the end of these Terms. You should check our Terms often to stay informed of any changes that may affect you. Your continued use of the Services after we change the Terms constitutes your acceptance of the changes. If you do not agree to any of the changes, you must cancel your account and no longer use the Marketplace or other Services.</p>

<h3>Using Ownish</h3>

<p>The Services are intended for use only by persons who are 18 years of age or older and are based in the United Kingdom. By using Ownish’s Services you confirm that you meet this requirement and that, if you are under the age of 18, you have received permission from your parent or guardian before using the Services.</p>

<p>Setting up your account and using the Services</p>

<p>Users must create an account to rent, lend, buy or sell on the Ownish Marketplace. When creating an account, we will ask you to provide your personal details – to see how we store and use your details, please see our Privacy Policy. We may also require additional forms of identification to verify your identity. You must provide accurate details about yourself when you create an account and not use any false details.</p>

<p>You will also be required to create a password for your account, and we encourage you to use “strong” passwords (passwords that use a combination of upper- and lower-case letters, numbers and symbols). You are responsible for maintaining the confidentiality of your account details (including passwords) and for any activities that occur under your account. If you have any concerns that your account has been misused or that your password has been compromised, please contact us at <a href="mailto:hello@ownish.co.uk">hello@ownish.co.uk</a> straight away. You may be held liable for losses incurred by us or any other person due to another person using your account or password. You may not use another user's account or password at any time without the express permission and consent of the holder of that account.</p>

<h3>Account closure</h3>

<p>You may close your account using the option in the app setting section, if you do not have any upcoming transactions or active conversations. If you do, then you can still close your account by contacting us at <a href="mailto:hello@ownish.co.uk">hello@ownish.co.uk</a> and requesting account closure.</p>

<h3>Uploading Content</h3>

<p>Ownish allows you to lend or sell items easily. You can do this by uploading pictures of your item and describing it.</p>

<p>You confirm that any images, text or information that you upload to the Ownish Marketplace are yours to use and do not infringe any brand’s intellectual property. You are solely responsible for ensuring that you have the necessary rights to allow Ownish to use the content you have uploaded onto the Marketplace and shall be solely liable if any of your content violates or infringes the intellectual property or privacy rights of any third party.</p>

<p>You also confirm that any reviews you post about other users or items are accurate and not abusive.</p>

<p>By uploading photos and content with your listings or reviews, you grant us a worldwide, non-exclusive, royalty-free and perpetual licence to use, copy, reproduce, distribute, adapt, re-format, modify, publish, translate, license

, sub-license and otherwise make available that content anywhere and in any form for the purposes of providing the Service.</p>

<p>We may monitor any user uploaded content, and have the right to reject, refuse or delete any content or account at our sole discretion.</p>

<h3>Making and receiving payments</h3>

<p>In order to lend or sell an item on Ownish, you must set up a Stripe Connect Account so that we can transfer any income you make. The Stripe Connect Account is operated by Stripe and you will be subject to the Stripe Connected Account Agreement and the Stripe Services Agreement. In order to sign-up to Stripe, you must agree to a Direct Debit mandate with us. The mandate authorises Ownish to debit your bank account by using the Bankers’ Automated Clearing Services (Bacs) if, for example, the net activity in your account on a day is negative or we need you to fund your account for any other reason relating to the Stripe Connected Account Agreement.</p>

<p>If we need to reimburse a renter or buyer you will be required to repay us for this amount and a debt will be created for the amount that you owe us for the refund. We will try to collect this amount from your bank account under the Direct Debit mandate. Whilst your Stripe Connect Account is in negative balance, Ownish may suspend or terminate your access to the Service in accordance.</p>

<p>All payments must take place via our third-party payment provider Stripe. Requesting, making or accepting payment for an item in any other way is a serious breach of the Terms and may result in us suspending or terminating your access to the Service.</p>

<h3>Fees and payments</h3>

<p>Ownish charges Listers a fee of 10% + 50p of the rental or resale price you choose for your item plus any postage costs paid by the Shopper.</p>

<p>We charge these fees to cover the cost of running Ownish, and advertising your items on Google and social media platforms.</p>

<p>Our payment provider Stripe, also charges 1.5% + 20p for processing the payment and transferring your transaction proceeds into your Stripe Connect Account. All fees are itemised in your Stripe Connect Account dashboards and via email and are deducted automatically.</p>

<p>Ownish charges Shoppers a fee of 2.5% of the total transaction value for using the Marketplace. This fee is added and paid for at checkout.</p>
<h3>Lister Specific Terms</h3>

<p>All items must conform to any descriptions provided by you, and you are responsible for ensuring that all items listed by you are fit for purpose and safe to use. Any damage to the item should be listed in the listing description and/or pictured in the item images.</p>

<p>Once your account is set up, you may begin to list items to lend or sell. You are fully responsible for ensuring that all information that you provide about an item is accurate.</p>

<p>You acknowledge and agree that Ownish does not verify any listings (including price, quality, accuracy, or fitness for purpose).</p>

<p>Ownish encourages you to upload images of you wearing the item yourself to show potential renters and/or buyers what the item looks like on.</p>

<p>We reserve the right to rank items based on our own internal processes and policies.</p>

<p>Separate to the item images uploaded to the Marketplace, we advise Listers to take photos of all items no more than 24 hours before the time of posting. These photos should show any existing damage (if applicable) and the condition of the item to help in case of dispute.</p>

<h3>Repeat Cancellations</h3>

<p>We understand that on some, rare occasions, a rental will need to be canceled by the Lister within 48 hours of the rental start date. However, where we see repeat or unreasonable cancellations by a Lister, we will, in the first instance, remind you of your responsibility to ensure rentals or purchase requests you accept are executed as expected. If it happens again, we reserve the right to close your account and remove your items from the Marketplace. We may also publish a review letting other users know you canceled a request after accepting it.</p>

<h3>Lister's Responsibilities</h3>

<p>You are responsible for ensuring that:</p>

<ol>
    <li>You have all the necessary rights to rent out any items you advertise on the Marketplace, and that the Shopper's use of those items will not infringe or misappropriate any other party's rights;</li>
    <li>You deliver any rented items to the Shopper in accordance with any terms which you have agreed with them;</li>
    <li>Any descriptions of the items you advertise for rent or sale through Ownish are accurate and include all information relevant to the use of the item, including (as appropriate):
        <ol type="i">
            <li>Notice of any defects, restrictions, or other requirements that may apply to the use of the item; and</li>
            <li>Any instructions or notices that may reasonably be required to use the item safely;</li>
        </ol>
    </li>
    <li>The items you advertise on the Service:
        <ol type="i">
            <li>Conform in all material respects to any pictures or descriptions that you upload to the Service;</li>
            <li>Are safe to use in accordance with any reasonable instructions that you provide to the Shopper;</li>
            <li>Are fit for any purpose for which such items would normally be used, or any purpose communicated to you by the Shopper; and</li>
            <li>Can be legally offered for rent or sale.</li>
        </ol>
    </li>
</ol>

<p>You are responsible for setting the terms on which you lend or sell an item including:</p>

<ol type="a">
    <li>Item price. The price you wish to charge to rent or sell the item</li>
    <li>Fit policy (applicable only to rentals): The amount you wish to refund the Renter in the event that the item does not fit and the item is returned within 24 hours of the rental start date. Postage costs are non-refundable.</li>
    <li>Refunds policy. Including the scenarios in which a Renter may request a refund in respect of a rental item. Listers cannot opt out of refunds in the following circumstances:
        <ol type="i">
            <li>The item is not as described or is defective</li>
            <li>The item does not arrive by the rental start date and is sent back by the renter within 48 hours unworn.</li>
            <li>The Lister cancels a confirmed rental request</li>
        </ol>
    </li>
</ol>

<p>In the above circumstances, the Lister will be required to pay the Commission to Ownish.</p>

<p>You are responsible for determining and fulfilling your obligations under applicable laws to report, collect, remit, or include in your price any applicable VAT or other indirect taxes, including income or other taxes.</p>

<p>Ownish advises against regular rentals of delicate outfits, as these will show signs of wear with repeated use due to the nature of the materials. If you choose to continue to lend items that are deemed delicate, this will be at the Lister’s risk. Renters will not be liable for general wear and tear. General wear and tear includes stains which can be removed by proper cleaning, minor snags, small numbers of missing beads, sequins missing, stuck zippers, or other minor damage.</p>

<h3>Cancellation Policy</h3>

<p>If a Renter wishes to cancel a rental, they should contact the Lister in the first instance directly to discuss the matter with them. If the Lister and Ownish agree to process a refund request made 15 or more days before the rental start date, the Renter will be refunded onto their original payment method, minus a small admin fee that covers the payment charges.</p>

<p>If the Renter cancels on or within 14 days of the rental start date, the Renter will be charged £10 or 10% of the rental value, whichever is greater. If the Lister has already posted the item, a refund may not be possible, and this will be reviewed on a case-by-case basis as agreed by the Lister. This is at the discretion of the Lister, not Ownish.</p>

<p>If a Renter has taken out the optional stress-free renting cover, this cover cannot be refunded in the event that a rental is canceled within 14 days.</p>

<p>Please note it can take up to 10 days for a refund to be processed.</p>

<p>Once you have agreed to the terms on which you will lend an item to a Renter, you must not amend those terms (including the Rental Fee) unless you have a valid, justifiable reason for doing so, and the Renter agrees in writing to those amended terms.</p>

<p>If you wish to sell items, you are solely responsible for ensuring that any purchase requests you accept are completed successfully. All sales are non-refundable, and you shall be fully liable for any issues arising following an accepted purchase request that is not completed.</p>

<h3>Renter Specific Terms</h3>

<p><strong>Your right to rent items.</strong></p>

<p>When you agree to rent an item from a Lister on the terms set by the Lister, the Lister grants you a limited right to use that item only for the relevant rental period. This right is personal to you, and you are not allowed to give this right to any other person.</p>

<p><strong>Renter's responsibilities.</strong></p>

<p>You are responsible for ensuring that:</p>

<ol>
    <li>You are legally allowed to use any item that you borrow through the Service;</li>
    <li>You comply with all applicable law when using the item;</li>
    <li>You comply with any reasonable directions provided by the Lister in order to use the item safely; and</li>
    <li>You return the item in the same condition as it was in when you collected or received it from the Lister, notwithstanding that the item has been worn and will need to be cleaned.</li>
</ol>

<p><strong>Returning an item at the end of a rental.</strong></p>

<p>You may agree with the Lister on how you will return any items that you have rented from the Lister; however, you must ensure that you post or return any items that you have rented on the agreed-upon return date, unless otherwise permitted by the Lister.</p>

<p>You are responsible for the loss of or damage to the rented item. Ownish has no responsibility if you lose or damage an item, and a Lister may seek damages from you for replacing or repairing the item you rented. A Lister may, in their sole discretion, charge you a fee in respect of any damage to or loss of their item, including fees associated with the repair, additional cleaning, or replacement of the item and shall be entitled to invoice you in respect of such a fee. Please note, the invoice will be sent from Ownish on behalf of the Lister, but the Lister is solely responsible for its issuance.</p>

<p>The Renter must notify the Lister of any damage that occurred during the rental as soon as possible.</p>

<p>The Lister must notify the Renter of any damage within 24 hours of the receipt of the returned item(s).</p>

<p><strong>What happens if you return an item late?</strong></p>

<p>If you are unable to return an item by the agreed return date, you must contact the Lister to notify them as soon as possible, letting them know that you are going to miss the agreed return deadline. You may be required to extend the length of your rental period and pay any additional fees due as a result of the extension.</p>

<p>If you fail to return the item(s), Ownish reserves the right to charge your payment method up to 200% of the retail value of the item and with any reasonable legal expenses incurred by Ownish or its partners in retrieving the items.</p>

<p>Theft of any item is not tolerated. If you steal or attempt to steal any item, the Lister and/or Ownish shall be entitled to take legal action against you.</p>

<h3>Postage</h3>

<p>We recommend both Listers and Renters send all items by tracked and insured post.</p>

<p>The sender will be liable to the other party for any loss or damage to items that occurred during postage. Ownish is not liable for any such loss or damage, howsoever incurred.</p>

<p>A tracking number must be provided for every item sent. If tracking is not available, Ownish may not be able to assist in resolving any disputes related to the rental. Once tracking is provided, the recipient is responsible for being available for delivery on the expected delivery date. If the tracking information is not provided, Ownish may assume the item has not been sent.</p>

<h3>Notice and Takedown Policy</h3>

<p><strong>Infringement Notices.</strong></p>

<p>Any person may contact us by sending us a notice (an "Infringement Notice") if any content available on the Website and/or App or through the use of the Service infringes their rights. The Infringement Notice should be sent by email to <a href="mailto:hello@ownish.co.uk">hello@ownish.co.uk</a>. Please provide the following information in the Infringement Notice:</p>

<ol>
    <li>Your name and contact details;</li>
    <li>A statement explaining in sufficient detail why you consider that the content available on the Website and/or App infringes your rights or fails to comply with our Acceptable Use Policy; and</li>
    <li>A link to or such other means of identifying the problematic content.</li>
</ol>

<p><strong>How we deal with Infringement Notices.</strong></p>

<p>We will take the action that we believe is appropriate depending on the nature of the Infringement Notice and will aim to respond to you within a reasonable period of time on the action we propose to take.</p>

<h3>How we Handle Disputes</h3>

<p><strong>Disputes with Ownish</strong></p>

<p>If you have a dispute with us relating to the Service, please email us on <a href="mailto:hello@ownish.co.uk">hello@ownish.co.uk</a>, and we will attempt to resolve the dispute.</p>

<p><strong>Disputes with other users.</strong></p>

<p>Ownish encourages all users to resolve any disputes directly with each other. If you are unable to resolve a dispute, our Team will try and help you to resolve it. You can contact us by emailing <a href="mailto:hello@ownish.co.uk">hello@ownish.co.uk</a>. You must provide the full details of the dispute together with sufficient evidence of any loss or damage suffered. Our Team will aim to be in touch within 2-3 business days from receiving notice of your dispute and do their best to resolve any such dispute but shall have no responsibility to conclude or resolve a dispute.</p>

<p>You must be able to provide sufficient evidence of any loss or damage suffered by you as a result of use of the Service. If you are unable to provide sufficient evidence, Ownish will not be able to help you mediate or resolve any dispute.</p>

<p>Photos of any damage should be taken within 24 hours of receipt of the item with a date stamp to show this. All disputes must be raised within 7 days of the rental end date. Disputes must be raised by either party directly between themselves or by emailing <a href="mailto:hello@ownish.co.uk">hello@ownish.co.uk</a>. Disputes raised more than 7 days after the rental end date may not be considered if, for example, a payment has already been processed.</p>

<p>Where Ownish steps in to mediate a dispute, you agree to accept Ownish's final determination of any dispute and pay any amounts owed by you. Any decision made by us in relation to a dispute will not affect your contractual and statutory rights nor right to take legal action.</p>

<h3>Ending our Relationship</h3>

<p>If at any time you do not feel that you can agree to these Terms or to any changes made to these Terms or the Service, you must immediately stop using the Service.</p>

<p>Ownish may immediately suspend or end your access to and use of the Service if you break or we reasonably suspect you of not adhering to the Terms.</p>

<p>Should you or we suspend or end your use of the Service, we may delete your Listings or any other information that you have uploaded to the Service or any other information we hold about you. You will also lose any rights you have to use the Service. We shall not be liable for any losses you suffer as a result of the suspension or termination of your use of the Service (for whatever reason).</p>

<p>The termination of your use of the Ownish Service and the cancellation of your Account shall not affect any of your obligations to pay any sums due to us or any other user of the Service.</p>

<h3>Our Liability and Responsibility to You</h3>

<p>We are responsible for foreseeable losses only. If we fail to comply with these Terms, we are responsible for any loss or damage you suffered that is a foreseeable result of our breaking this contract or failing to use reasonable care and skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time the contract was made, both we and you know it might happen.</p>

<p>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or negligence of our employees, agents, or subcontractors; for fraud or fraudulent misrepresentation.</p>

<p>We are not liable for business losses. We only supply the items for domestic and private use. If you use the items for any commercial, business or re-sale purpose we will have no liability to you for any loss of profit, loss of business, business interruption or loss of business opportunity.</p>

<p>We do not carry out any verification checks on the items listed for rent on the Service. As such, we do not warrant or guarantee (i) the quality, size or legality of any items listed for rent on the Service or the legality of any User Content made available on the Service, the App, and/or Website; or (ii) the truth or accuracy of the items listed for rent or User Content.</p>

<h3>Other Important Terms</h3>

<p>We cannot be held responsible for events that happen outside of our control. This includes outages or disruptions of the internet and telecommunications infrastructure which are beyond our control and can lead to interruptions in the availability of the Service.</p>

<p><strong>Entire agreement.</strong> These Terms and any document referred to in them (as each may be amended from time to time) constitute the entire agreement between you and Ownish relating to your use of our Services and supersede all prior understandings and agreements between parties. If a court or other authority decides that some of these terms are unlawful, the rest will continue to apply.</p>

<p>These Terms are between you and us. Nobody else can enforce it and neither of us will need to ask anybody else to sign-off on ending or changing it.</p>

<p><strong>Our right to assign these Terms.</strong> Ownish may assign or transfer our rights and obligations under these Terms at any time. We'll tell you in writing if this happens and ensure that the transfer won't affect your rights under the contract. You can only transfer your contract with us with our prior agreement.</p>

<p>You may not enter into any contract on our behalf or bind us in any way. These Terms are for your benefit only, not for the benefit of any third party of yours.</p>

<p>English law governs these Terms. Any disputes that arise out of or in connection with these Terms, the App and Website, or the Services shall be governed exclusively by the courts of England.</p>

<p>These terms were updated on 22 July 2023</p>

          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TermsOfServices;
